// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// @ts-ignore
// never import from index.ts, it causes circular reference error, hence, compilation error during karma test
// index.ts tends to import those services as well and services use those LCMS constants where environment is once again imported in this ts file.
import {Menu} from '../app/utility/enums/menu.enum';
import { LcmsEnvironment } from '../../app/utility/models/lcms-environment.interface';

const SUPERADMIN = [
  Menu.COMPANY,
  Menu.USER,
  Menu.USERGROUPS,
  Menu.LESSONS,
  Menu.GLOSSARY,
  Menu.PARTNER,
  Menu.STATS,
  Menu.SYSTEMTEXT,
  Menu.STATICCONTENT,
  Menu.PAGE_NEXT,
  Menu.LESSONPATHS,
  Menu.ASSIGNMENT,
  Menu.SPONSORS,
  Menu.HERO
];

const PORTALADMIN = [
  Menu.USER,
  Menu.COMPANY,
  Menu.USERGROUPS,
  Menu.LESSONS,
  Menu.LESSONPATHS,
  Menu.ASSIGNMENT,
  Menu.GLOSSARY,
  Menu.STATS,
  Menu.SYSTEMTEXT,
  Menu.STATICCONTENT,
  Menu.PAGE_NEXT,
  Menu.SPONSORS,
  Menu.HERO
];

const COMPANYADMIN = [
  Menu.USER,
];

export const environment: LcmsEnvironment = {
  production: false,

  statisticsKey: 'lz',

  server: {
    api     : 'https://dfv-api.nettrek.dev/backend',
    apiNext : 'https://staging.lcms-next.dev.nettrek.group',
    frontend: 'https://lzdirekt.nettrek.dev/',
    tenant  : 'lz'
  },

  roles: [
    SUPERADMIN,
    PORTALADMIN,
    COMPANYADMIN
  ],
  difficulties : [
    {
      value : 1,
      label: 'Leicht'
    },
    {
      value : 2,
      label: 'Ausgewogen'
    },
    {
      value : 3,
      label: 'Schwer'
    }
  ],
  classifications : [
    {
      value : 'demo',
      label : 'Demo'
    },
    {
      value : 'basic',
      label: 'Basic',
    },
    {
      value : 'pro',
      label : 'Pro'
    }
  ],
  tenantConfig: {
    lessonPathDetails: {
      chapters: {
        duration: true,
        classification: true,
        sequence: true,
      }
    },
    user: {
      groups: true,
      exportUserList: true,
      hasAdditionalFields: true,
      usernameRequired: true,
    },
    company: {
      inviteCodes: true
    },
    sponsors: {
      enabled: true,
    },
    certificate: {
      forLessons: true,
      description: true,
      subline: true,
      sponsor: true,
    },
  },
  createDefaults: {
    lessonPaths: {}
  },
  trackingUrl: 'https://api-lcms.e5-suite.de/frontend/eagle5trackings/',
  lessonLink: ['https://api-lcms.e5-suite.de/wbts/lz/lesson_', '/eagle5_scorm_adapter.html?startpage='],
};
//
// /*
//  * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
import 'zone.js/dist/zone-error';
